#checkOutContainer {
  background-image: url("../../assests/checkOutBgImg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 980px;
  margin-top: -90px;
  overflow-x: hidden;
}

#checkOutText {
  font-family: "Hallowest";
  font-weight: 400;
  font-size: 45px;
  line-height: 72.02px;
  color: #ffffff;
}
#subTitle {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.video-wrapper {
  display: flex;
  justify-content: center;
}

.video-wrapper iframe {
  width: 100%;
  max-width: 878px;
  height: 455px;
  border-radius: 18px;
  border: none;
}

#YoutueText_1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

#YoutueText_2 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

@media (max-width: 678px) {
  #checkOutContainer {
    height: 100%;
    margin-bottom: 100px;
  }
  .video-wrapper iframe {
    width: 93%;
  }
  #bottomText{
    padding-bottom: 30px;
  }
}

.mobile-footer {
  display: none;
}

@media (max-width: 768px) {
  .mobile-footer {
    display: block;
  }

  .desktop-footer {
    display: none;
  }
}
